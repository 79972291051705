import { render, staticRenderFns } from "./TelegramChannels.vue?vue&type=template&id=72f00ab4&scoped=true&"
import script from "./TelegramChannels.vue?vue&type=script&lang=js&"
export * from "./TelegramChannels.vue?vue&type=script&lang=js&"
import style0 from "./TelegramChannels.vue?vue&type=style&index=0&id=72f00ab4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f00ab4",
  null
  
)

export default component.exports